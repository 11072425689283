import axios from './axios'

export async function request (options) {
  return axios({
    url: options.url,
    method: options.method || 'post',
    data: options.data || {}
  })
}

export async function sdkRequest (options) {
  let gameId = localStorage.getItem("gameId");
  options.data.device = '';
  options.data.systemVersion = '';
  options.data.udid = '';
  options.data.gameId = gameId;
  options.data.sdkVersion = '';
  options.data.time = new Date().getTime();

  return axios({
    url: options.url,
    method: 'post',
    data: options.data
  })
}

export async function post (options) {
  options.data.gameId = localStorage.getItem("gameId");

  return axios({
    url: options.url,
    method: 'post',
    data: options.data
  })
}

export async function get (url) {
  return axios({
    url: url,
    method: 'get',
  })
}



