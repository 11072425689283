<template>
    <div>
        <!-- 登录页面 -->
        <div v-if="step === 1">
            <login ref="loginRef"></login>
        </div>

        <div v-if="step === 2">
            <van-dialog v-model="realNameDialogShow" :showConfirmButton="false">
                <real-name ref="realNameRef"></real-name>
            </van-dialog>
        </div>

        <div v-if="step === 3">
            <van-dialog  v-model="selectAccountShow" :showConfirmButton="false" style="height: 320px">
                <select-account  ref="loginAccountRef"></select-account>
            </van-dialog>
        </div>

        <div  v-if="step === 4" class="back-div">
            <div class="game-page">

                <pay-confirm ref="payConfirmRef" v-if="iOSPayConfirmShow"></pay-confirm>

                <iframe v-show="true" allowfullscreen ref="gameIframe" :src="gameUrl" frameborder="0" scrolling="no" width="100%" height="100%"></iframe>

                <van-dialog  v-model="payShow" :showConfirmButton="false">
                    <pay ref="payRef"></pay>
                </van-dialog>

                <van-dialog v-if="payScanCodeShow" v-model="payScanCodeShow" :showConfirmButton="false" style="width: 70%;max-width: 260px;">
                    <pay-scan-code ref="payScanCodeRef"></pay-scan-code>
                </van-dialog>

                <van-dialog v-if="mainMenuShow"  v-model="mainMenuShow" :showConfirmButton="false" close-on-click-overlay>
                    <main-menu ref="mainMenuRef" v-if="mainDialog === 1"></main-menu>
                    <password ref="passwordRef" v-if="mainDialog === 2"></password>
                    <kf ref="kfRef" v-if="mainDialog === 3"></kf>
                    <account ref="accountRef" v-if="mainDialog === 4"></account>

                </van-dialog>

            </div>


            <div v-if="floatIconShow" class="float-icon" id="moveDiv"
                 @mousedown="down($event)"
                 @touchstart="down($event)"
                 @mousemove="move($event)"
                 @touchmove="move($event)"
                 @mouseover="end($event)"
                 @mouseleave="move($event)"
                 @mouseup="end($event)"
                 @touchend="end($event)"
                 @click="showMainWindow">
                  
                <img src="../../assets/logo-round.png" style="width: 50px;height: 50px;"/>
                <div v-show="false">
                    <van-count-down
                            ref="countDown"
                            time="10000"
                            :auto-start="false"
                            format="ss"
                            @finish="finish"
                    />
                </div>
            </div>

        </div>

    </div>
</template>

<script>

    import {Dialog, Toast} from 'vant';
    import payConfirm from "@/components/PayConfirm";
    import pay from "@/components/Pay";
    import payScanCode from "@/components/PayScanCode";
    import mainMenu from "@/components/MainMenu";
    import password from "@/components/Password";
    import kf from "@/components/Kf";
    import account from "@/components/Account";
    import login from "@/components/Login";
    import selectAccount from "@/components/SelectAccount";
    import realName from "@/components/RealName";
    import {request} from "@/api/request";

    export default {
        name: "GameMain",
        components: {
            payConfirm,
            pay,
            payScanCode,
            mainMenu,
            password,
            kf,
            account,
            login,
            selectAccount,
            realName,
        },
        data() {
            return {
                step: 1,
                gameUrl: '',
                gameId: '0',
                iOSPayConfirmShow: false,
                payShow: false,
                payScanCodeShow: false,
                mainDialogShow: false,
                mainMenuShow: false,
                passwordShow: false,
                mainDialog: 1,

                selectAccountShow: true,
                realNameDialogShow: false,

                floatIconShow: true,
                buttonHidden: true,
                flags: false,
                position: {x: 0, y: 0},
                nx: '', ny: '', dx: '', dy: '', xPum: '', yPum: '',

                payUrl: '',
            }
        },
        mounted() {
            console.log('页面初始化');
            this.gameId = this.$route.path.split('/')[2];
            localStorage.setItem('gameId', this.gameId);
            window.addEventListener("message", this.listener);
        },
        methods: {
            showPayConfirm(url) {
                this.iOSPayConfirmShow = true;
                this.$nextTick(()=>{
                    this.$refs.payConfirmRef.payUrl = url;
                })
            },
            setGameUrl(url) {
                this.gameUrl = url;
                //this.gameUrl = 'http://gw.daoyigame.com/#/game/test';
                this.step = 4;
            },
            checkRealName() {
                let user = JSON.parse(localStorage.getItem("user"));
                if (user.isUserCheck !== 1) {
                    this.step = 2;
                    this.show('realName');
                }
                else {
                    this.showSelectAccount();
                }
            },
            showSelectAccount() {
                this.step = 3;
                this.show('account');
            },
            show(tag) {
                switch (tag) {
                    case 'pay':
                        this.payShow = true;
                        break;
                    case 'mainMenu':
                        this.mainMenuShow = true;
                        this.mainDialog = 1;
                        break;
                    case 'password':
                        this.mainDialog = 2;
                        break;
                    case 'kf':
                        this.mainDialog = 3;
                        break;
                    case 'account':
                        this.mainDialog = 4;
                        break;
                    case 'realName':
                        this.realNameDialogShow = true;
                        break;
                }
            },
            close(tag) {
                switch (tag) {
                    case 'pay':
                        this.payShow = false;
                        break;
                    case 'payConfirm':
                        this.iOSPayConfirmShow = false;
                        break;
                    case 'payScanCode':
                        this.payScanCodeShow = false;
                        break;
                    case 'mainMenu':
                        this.mainMenuShow = false;
                        break;

                }
            },
            showMainWindow() {
                if (this.buttonHidden) {
                    this.activeButton();
                    this.start();
                    return;
                }
                /*if (this.firstClick) {
                  this.firstClick = false;
                  return;
                }*/
                this.show('mainMenu');
                this.$nextTick(function () {

                })
            },
            closeMainWindow() {
                this.mainDialogShow = false;
                this.activeButton();
                this.start();
            },
            listener(event) {
                if (event.data.operation === 'openPayPage') {
                    console.log("触发支付");
                    let order = event.data.param;
                    this.openPayPage(order);
                } else if (event.data.operation === 'reportRoleInfo') {
                    console.log("触发角色上报");
                    let param = event.data.param;
                    this.reportRoleInfo(param);
                } else if (event.data.operation === 'offline') {
                    console.log("触发登陆被顶");
                    Toast('游戏角色在其他地方登录，您已下线');
                    this.logout();
                }
            },
            openPayPage(order) {
                this.show('pay');
                this.$nextTick(function () {
                    this.$refs.payRef.initOrder(order);
                })
            },
            reportRoleInfo(roleParam) {
                console.log(roleParam);
                let options = {
                    url:"/h5/roleSync",
                    data: {
                        gameId: roleParam.gameId,
                        remark: roleParam.remark,
                        roleId: roleParam.roleId,
                        roleLevel: roleParam.roleLevel,
                        roleName: roleParam.roleName,
                        serverId: roleParam.serverId,
                        serverName: roleParam.serverName,
                        userId: roleParam.userId,
                        combatNum: roleParam.combatNum,
                        vipLevel: roleParam.vipLevel,
                        time: roleParam.time,
                        sign: roleParam.sign
                    }
                };
                request(options).then(res => {
                    if (res.data.success) {
                        console.log('角色上报成功');
                    }
                    else {
                        console.log('角色上报失败:' + res.data.error.message);
                    }
                });
            },
            openPayUrl(url, payType, price, isScan, orderId) {
                this.close('pay');
                if (!isScan && this.isMobile()) {
                    this.payUrl = url;
                    let ua = navigator.userAgent.toLowerCase();
                    if (/iphone|ipad|ipod/.test(ua)) {//判断是否ios
                        this.showPayConfirm(this.payUrl);
                    }
                    else {
                        window.open(this.payUrl, '');
                    }
                } else {
                    let scanData = {
                        orderId: orderId,
                        payType: payType,
                        payUrl : url,
                        price: price,
                    }
                    this.payScanCodeShow = true;
                    this.$nextTick(()=>{
                        this.$refs.payScanCodeRef.initPage(scanData);
                    })
                }

            },
            logout() {
                let options = {
                    url:"/h5/logout",
                    data: {}
                };
                request(options).then(res => {
                    if (res.data.success) {
                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        localStorage.removeItem('accountId');
                        window.location.reload();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            activeButton() {
                this.buttonHidden = false;
                if (!moveDiv.style.left) {
                    moveDiv.style.left = "0px"
                }

                if (moveDiv.style.left === '-25px') {
                    moveDiv.style.left = "0px"
                }
                else if (moveDiv.style.right === '-25px') {
                    moveDiv.style.right = "0px"
                }
            },
            unActiveButton() {
                this.buttonHidden = true;
                if (moveDiv.style.left === '0px') {
                    moveDiv.style.left = "-25px"
                }
                else if (moveDiv.style.right === '0px') {
                    moveDiv.style.right = "-25px"
                }
            },
            start() {
                this.$refs.countDown.reset();
                this.$refs.countDown.start();
            },
            reset() {
                this.$refs.countDown.reset();
            },
            finish() {
                this.unActiveButton();
            },
            down(event) {
                this.flags = true;
                let touch;
                if (event.touches) {
                    touch = event.touches[0];
                } else {
                    touch = event;
                }
                this.position.x = touch.clientX;
                this.position.y = touch.clientY;
                this.dx = moveDiv.offsetLeft;
                this.dy = moveDiv.offsetTop;
            },
            move(event) {
                if (this.flags) {
                    if (this.buttonHidden) {
                        this.activeButton();
                    }
                    let touch;
                    if (event.touches) {
                        touch = event.touches[0];
                    } else {
                        touch = event;
                    }
                    this.nx = touch.clientX - this.position.x;
                    this.ny = touch.clientY - this.position.y;
                    this.xPum = this.dx + this.nx;
                    this.yPum = this.dy + this.ny;
                    moveDiv.style.left = this.xPum + "px";
                    moveDiv.style.top = this.yPum + "px";
                    //阻止页面的滑动默认事件；如果碰到滑动问题，1.2 请注意是否获取到 touchmove
                    document.addEventListener("touchmove", function () {
                        event.preventDefault();
                    }, false);
                }
            },
            end(event) {

                this.flags = false;
                if (!this.xPum) {
                    return;
                }

                let {
                    clientHeight: windowHeight,
                    clientWidth: windowWidth
                } = document.documentElement;
                // 计算后坐标  设置 按钮位置
                //this.halfOffset = -moveDiv.clientWidth / 2;
                if (this.yPum > 50 && this.yPum < (windowHeight - 150)) { //  不在顶部 且 不在底部
                    if (this.xPum <= (windowWidth / 2)) {  //  left 小于等于屏幕一半
                        moveDiv.style.left = 0;
                        moveDiv.style.right = 'auto';
                        //this.hiddenOnLeft();
                        this.start();
                    } else { //  left 大于屏幕一半
                        moveDiv.style.left = 'auto';
                        moveDiv.style.right = 0;
                        this.start();
                    }
                } else {
                    if (this.yPum <= 50) {  //  在顶部
                        moveDiv.style.top = 0;
                        moveDiv.style.bottom = 'auto';
                        this.start();
                    } else if (this.yPum >= (windowHeight - 150)) {
                        moveDiv.style.bottom = 0;
                        moveDiv.style.top = 'auto';
                        this.start();
                    }
                    if (this.xPum < 0) {
                        moveDiv.style.left = 0;
                        this.start();
                    } else if (this.xPum > (windowWidth - moveDiv.clientWidth)) {
                        moveDiv.style.right = 0;
                        moveDiv.style.left = 'auto';
                        this.start();
                    }
                }
            },
            isMobile() {
                let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
                return flag;
            },
        }
    }
</script>

<style scoped>
    .game-page {
        position: relative;
        background-color: #ffffff;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        touch-action: none;
        margin: 0 auto;
    }

    .van-dialog {
        border-radius: 8px;
    }
    .float-icon {
        position: fixed;
        touch-action: none;
        height: 50px;
        width: 50px;
        /* 如果碰到滑动问题，1.3 请检查 z-index。z-index需比web大一级*/
        z-index: 999;
        top: 60%;
        left: -25px;
    }
</style>