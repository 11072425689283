<template>
    <div class="main-view">
        <home-nav></home-nav>

        <gzh-code-view></gzh-code-view>
        <div class="center-view">

            <div style="width: 100%;max-width: 1200px;margin: 50px auto 0; position: relative">

                <div style="display: flex;align-items: center;position: absolute;top: 320px;">
                    <div class="code-view">
                        <img src="../../assets/demo_1.png" style="width: 105px;height: 105px;">
                        <span style="font-size: 10px;color: #666666;margin-top: 10px;">扫码下载app</span>
                    </div>

                    <div style="margin-left: 50px;">
                        <div class="download-button">
                            <img src="../../assets/android.png" style="width: 20px;height: 20px;margin-right: 10px;">
                            安卓版下载
                        </div>
                        <div class="download-button" style="margin-top: 20px">
                            <img src="../../assets/iOS.png" style="width: 20px;height: 20px;margin-right: 10px;">
                            苹果版下载
                        </div>
                    </div>

                </div>

                <div class="tel-view">

                </div>

            </div>


        </div>

        <home-footer></home-footer>
    </div>
</template>

<script>
    import homeNav from "@/components/HomeNav";
    import homeFooter from "@/components/HomeFooter";
    import gzhCodeView from "@/components/GzhCodeView";

    export default {
        name: "HomeDownloadApp",
        components: {
            homeNav,
            homeFooter,
            gzhCodeView,
        },
        data() {
            return {}
        },
        mounted() {

        },
        methods: {

        }
    }
</script>

<style scoped>
    .main-view {
        min-width: 1400px;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: #eaeef3;
    }
    .center-view {
        display: flex;
        width: 100%;
        height: calc(100vh - 80px);
        justify-content: space-between;
        background-image: url(../../assets/game/bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .tel-view {
        width:280px;
        height: 550px;
        background-color: #ffffff;
        border-radius: 25px;
        position: absolute;
        right: 15px;
    }
    .download-button {
        width: 180px;
        height: 50px;
        border-radius: 40px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        padding: 0 25px;
        box-sizing: border-box;
        color: #444444;
        user-select: none;
        cursor: pointer;
    }
    .code-view {
        width: 125px;
        height: 145px;
        background-color: #ffffff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
</style>