<template>
    <div class="code-view-main">
        <img src="../assets/gzh.jpg" style="width: 95px;height: 95px;">
        <span style="font-size: 10px;color: #333333;margin-top: 5px;">扫码关注微信公众号</span>
    </div>
</template>

<script>
    export default {
        name: "GzhCodeView"
    }
</script>

<style scoped>
    .code-view-main {
        position: fixed;
        right: 30px;
        top: 45%;
        padding: 10px;
        background-color: #ffffff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 999;
    }
</style>