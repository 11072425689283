let daoyiInstance = window.DAOYI_SDK || {};
daoyiInstance.clientVersion = "1.0.0";
daoyiInstance.gameId = 0;
daoyiInstance.init = function () {

};

daoyiInstance.config = function (params) {
    daoyiInstance.gameId = params.gameId;
};

daoyiInstance.openPayPage = function (order) {
    sendMsgToParent({operation: "openPayPage", param: order});
    try {
        window.androidWebviewJS.openPayPage(JSON.stringify(order));
    } catch (e) {
    }
};

daoyiInstance.reportRoleInfo = function (param) {
    sendMsgToParent({operation: "reportRoleInfo", param: param});
    try {
        window.androidWebviewJS.reportRoleInfo(JSON.stringify(param));
    } catch (e) {
    }
};

daoyiInstance.offline = function () {
    sendMsgToParent({operation: "offline"});
    try {
        window.androidWebviewJS.offline();
    } catch (e) {
    }
};

daoyiInstance.init();
window.DAOYI_SDK = daoyiInstance;

function sendMsgToParent(post) {
    window.parent.postMessage(post, '*')
}
