import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import {
  Button,
  Icon,
  Grid,
  GridItem,
  Image as VanImage,
  Search,
  Divider,
  Tag,
  Calendar,
  List,
  Cell,
  CellGroup,
  Dialog,
  Toast,
  NavBar,
  Picker,
  Popup,
  Field,
  Form,
  CountDown,
  Loading,
  Popover,
  Checkbox,
  Col,
  Row,
  RadioGroup,
  Radio,
  Overlay,
  Tab,
  Tabs,
  Empty,
  Sticky,
} from 'vant';
import 'vant/lib/index.css';

Vue.use(Button)
Vue.use(Icon)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(VanImage)
Vue.use(Search)
Vue.use(Divider);
Vue.use(Tag);
Vue.use(Calendar);
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(NavBar);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Form);
Vue.use(CountDown);
Vue.use(Loading);
Vue.use(Popover);
Vue.use(Checkbox);
Vue.use(Col);
Vue.use(Row);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Overlay);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Empty);
Vue.use(Sticky);

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
