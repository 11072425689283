import axios from 'axios'
import SERVERS from '@/api/servers.js'
import {Toast} from "vant";

axios.defaults.baseURL = SERVERS.BASEURL

// http request 拦截器
axios.interceptors.request.use(
  config => {
    config.headers['token'] = localStorage.getItem('token')
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// 返回状态判断
axios.interceptors.response.use(
  (response) => {
    // 登录token失效
    if (response.data.error && response.data.error.code === '911') {
        Toast('登录已失效，请重新登录');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('accountId');
    }
    return response
  },
  (error) => {
      return {
          data: {
              success: false,
              error: {
                  message: '网络异常，请稍后重试'
              }
          },
      }
  }
)
export default axios
