<template>
    <div class="main-container">
        <van-button @click="pay">支付</van-button>
        <van-button @click="uploadGameRole">上报角色</van-button>
        <van-button @click="repeatLogin">登录被顶</van-button>
    </div>
</template>

<script>

    import daoyiSdk from '@/utils/sdk/daoyi-sdk';
    import {md5} from '@/utils/md5';
    import {Dialog, Toast} from 'vant'

    export default {
        name: 'gameTest',
        data() {
            return {
                daoyiSDK: window.DAOYI_SDK,
            };
        },
        mounted() {
            let params = {
                gameId: '107'
            }
            this.daoyiSDK.config(params);
        },
        methods: {
            uploadGameRole() {
                let roleParam = {
                    gameId: "107",
                    userId: "7",
                    serverId: "17001",
                    serverName: "电信1区",
                    roleId: "122001",
                    roleName: "航十三",
                    roleLevel: "10",
                    combatNum: "12000000",//战斗力
                    vipLevel: "12",//vip等级
                    time: new Date().getTime() + "",
                    remark: "{'serverId':'17001','serverName':'电信1区'}",
                    sign: "sign",
                    signType: "md5"
                };
                this.daoyiSDK.reportRoleInfo(roleParam);
            },
            //⽤户点击商品下单
            pay() {
                let userId = localStorage.getItem('accountId');
                let order = {
                    gameId: "107",
                    userId: userId,
                    serverId: "17001",
                    serverName: "电信1区",
                    roleId: "122001",
                    roleName: "航十三",
                    goodsName: "100元宝",
                    money: "100",
                    cpOrderId: "daoyi-test-" + new Date().getTime(),
                    cpExt: "透传参数",
                    time: new Date().getTime() + "",
                    sign: "",
                    signType: "md5"
                };
                let signArray = [];
                Object.keys(order).forEach(key => {
                    if (key !== 'sign' && key !== 'signType' && key !== 'cpExt' ) {
                        signArray.push(key + "=" + order[key]);
                    }
                })
                signArray.sort();
                let signStr = '';
                for (let i = 0; i < signArray.length; i++) {
                    if (i === 0) {
                        signStr += signArray[i];
                    }
                    else {
                        signStr = signStr + '&' + signArray[i]
                    }
                }
                signStr += '&3536347c2ded4c45819af4ace36a4939';
                console.log(signStr);
                order.sign = md5(signStr);
                this.daoyiSDK.openPayPage(order);
            },
            //登录被顶
            repeatLogin() {
                this.daoyiSDK.offline();
            },

            success() {
                console.log('上报成功啦')//该⽅法仅供参考
            }
        }
    }
</script>

<style scoped>
    .main-container {

    }
</style>
