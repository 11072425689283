<template>
        <div style="height: 80px;background-color: #3b3b3b;width: 100%;">
            <div class="nav-center">
                <div class="logo-view">
                    <img src="../assets/logo-main.png" style="width: 80px;" >
                    <span style="margin-left: 7px;font-weight: bold;color: #efefef;font-style: italic;font-size: 25px;">星游平台</span>
                </div>
                <div class="menu-view">
                    <div class="menu" :class="index === 1 && 'menu-active'"  @click="toMainPage">
                        游戏推荐
                        <div class="active-line" :class="preIndex > 1 ? 'from-right' : 'from-left'" v-show="index === 1"></div>
                    </div>
                    <div class="menu" :class="index === 2 && 'menu-active'" @click="toGameListPage">
                        所有游戏
                        <div class="active-line" :class="preIndex > 2 ? 'from-right' : 'from-left'" v-show="index === 2"></div>
                    </div>
                    <div class="menu" :class="index === 3 && 'menu-active'" @click="toIntroPage">
                        游戏攻略
                        <div class="active-line" :class="preIndex > 3 ? 'from-right' : 'from-left'" v-show="index === 3"></div>
                    </div>
                    <div class="menu" :class="index === 4 && 'menu-active'" @click="toKfPage">
                        联系客服
                        <div class="active-line" :class="preIndex > 4 ? 'from-right' : 'from-left'" v-show="index === 4"></div>
                    </div>
                    <div class="menu" :class="index === 5 && 'menu-active'" @click="toAppPage">
                        APP下载
                        <div class="active-line" :class="preIndex > 5 ? 'from-right' : 'from-left'" v-show="index === 5"></div>
                    </div>
                   <!-- <div class="menu" :class="index === 6 && 'menu-active'" @click="toAboutUsPage">
                        关于我们
                        <div class="active-line" :class="preIndex > 6 ? 'from-right' : 'from-left'" v-show="index === 6"></div>
                    </div>-->
                </div>
                <div class="search-view">
                    <div style="display: flex;justify-content: flex-start;align-items: center">
                        <img src="../assets/search-icon.png" style="width: 20px;height: 20px" alt="">
                        <span style="width: 2px;height: 20px;background-color: #dbdbdb;margin-left: 10px;"></span>
                        <input  type="text" placeholder="查找游戏" class="search-input">
                    </div>
                    <div class="search-button">搜索</div>
                </div>
                <div>
                    <img src="../assets/user-icon.png" style="width: 25px;height: 25px;cursor: pointer">
                </div>
            </div>
        </div>
</template>

<script>
    import demo2Img from "@/assets/demo_2.png";

    export default {
        name: "HomeNav",
        data() {
            return {
                index: 0,
                preIndex: '',
            }
        },
        mounted() {
            this.initActiveMenuIndex();
            this.preIndex = localStorage.getItem('preIndexMenu')
            console.log('this.index:' + this.index + "    this.preIndex: " + this.preIndex);
        },
        methods: {
            initActiveMenuIndex() {
                let path = this.$route.path;
                if (path === '/home/main') {
                    this.index = 1;
                }
                else if (path === '/home/gameList') {
                    this.index = 2;
                }
                else if (path === '/home/intro') {
                    this.index = 3;
                }
                else if (path === '/home/kf') {
                    this.index = 4;
                }
                else if (path === '/home/app') {
                    this.index = 5;
                }
            },
            toMainPage() {
                localStorage.setItem('preIndexMenu', this.index)
                this.$router.push('/home/main');
            },
            toGameListPage() {
                localStorage.setItem('preIndexMenu', this.index)
                this.$router.push('/home/gameList');
            },
            toIntroPage() {
                localStorage.setItem('preIndexMenu', this.index)
                this.$router.push('/home/intro');
            },
            toKfPage() {
                localStorage.setItem('preIndexMenu', this.index)
                this.$router.push('/home/kf');
            },
            toAppPage() {
                localStorage.setItem('preIndexMenu', this.index)
                this.$router.push('/home/app');
            },
            toAboutUsPage() {
                localStorage.setItem('preIndexMenu', this.index)
                this.$router.push('/home/us');
            },

        }
    }
</script>

<style scoped>
    .nav-center {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo-view {
        height: 80px;
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #FFFFFF;
    }
    .menu-view {
        display: flex;
    }
    .menu {
        height: 80px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
        user-select: none;
        position: relative;
    }
    .menu-active {
        color: #f6bc2b;
    }
    .active-line {
        width: calc(100% - 40px);
        height: 3px;
        background-color: #f6bc2b;
        position: absolute;
        left: 20px;
        bottom: 0;

    }

    .from-right {
        animation: right_to_left .3s linear;
    }

    .from-left {
        animation: left_to_right .3s linear;
    }

    @keyframes left_to_right {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(0);
        }
    }

    @keyframes right_to_left {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0);
        }
    }

    .search-view {
        height: 40px;
        width: 220px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 40px;
        padding: 0 5px 0 10px;
    }
    .search-input {
        background: transparent;
        outline: none;
        border: none;
        height: 30px;
        margin-left: 10px;
        width: 120px;
    }
    .search-button {
        height: 30px;
        width: 60px;
        background-color: #4a8fff;
        color: #ffffff;
        border-radius: 30px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>