<template>
    <div class="main-view">
        <home-nav></home-nav>
        <gzh-code-view></gzh-code-view>

        <div style="width: 100%;min-height: calc(100vh - 380px); max-width: 1200px;margin: 0 auto;justify-content: space-between;">
            <div style="width: 100%;background-color: #ffffff;border-radius: 10px;margin-top: 20px;height: fit-content">
                <div style="height: 70px;padding-left: 50px;display: flex;align-items: center;color: #666666">
                    <span>当前位置：</span>
                    <span style="margin-left: 10px;">首页</span>
                    <span style="margin-left: 10px;">></span>
                    <span style="margin-left: 10px;">所有游戏</span>
                </div>
                <div style="height: 0.5px;background-color: #e9e9e9;margin: 0 50px;"></div>
                <div style="display: flex;align-items: center;padding: 0 50px;height: 70px;">
                    <span style="font-size: 15px;font-weight: bold">游戏分类</span>
                    <span class="game_type"  v-for = "(item,index) in tagList"   :class="selectGameType === index && 'game_type_selected'" @click="selectType(index)">{{item.name}}</span>
                </div>
            </div>

            <div v-if="gameList.length > 0"  class="game-center" style="width: 100%;margin-top: 30px;display: flex;flex-wrap: wrap;justify-content: flex-start;">
                <div class="game-item-view" v-for="item in gameList" @click="toDetail(item.gameId)">
                    <img :src="item.icon" style="width: 90px;height: 90px;border-radius: 20px;">
                    <div style="display: flex;height: 70px; margin-left: 15px;flex-direction: column;justify-content: space-between">
                        <span class="game-name-one-line">{{item.gameName}}</span>
                        <div class="game-tag-view">
                            <span v-for="tag in item.tagList">{{tag}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else style="font-size: 20px;width: 100%;text-align: center;margin: 150px 0;color: #888888">
                暂时还没有内容哦，请等等我们~
            </div>

            <div class="common-view">
                <span style="font-size: 14px;color: #666666;margin-right: 15px;">总计 {{total}} 条</span>
                <van-pagination v-model="currentPage" :total-items="total" :show-page-size="5" :items-per-page="pageSize"  @change="changePage">
                    <template #prev-text>
                        <span style="margin-left: 10px;">
                            上一页
                             <van-icon name="arrow-left"  style="margin-right: 10px;"/>
                        </span>
                    </template>
                    <template #next-text>
                        <span style="margin-left: 10px;">
                            下一页
                             <van-icon name="arrow"  style="margin-right: 10px;"/>
                        </span>
                    </template>
                    <template #page="{ text }">{{ text }}</template>
                </van-pagination>

            </div>

        </div>

        <home-footer></home-footer>
    </div>
</template>

<script>
    import {request} from "@/api/request";
    import homeNav from "@/components/HomeNav";
    import homeFooter from "@/components/HomeFooter";
    import gzhCodeView from "@/components/GzhCodeView";
    import Vue from 'vue';
    import {Pagination, Toast} from 'vant';

    Vue.use(Pagination);

    export default {
        name: "GameList",
        components: {
            homeNav,
            homeFooter,
            gzhCodeView,
        },
        data() {
            return {
                selectGameType: 0,
                currentPage: 1,
                tagList: [
                    {name: '全部', key: '0'},
                    {name: '卡牌', key: '1'},
                    {name: '传奇', key: '2'},
                    {name: '三国', key: '3'},
                    {name: '放置', key: '4'},
                    {name: '策略', key: '5'},
                    {name: '模拟', key: '6'},
                    {name: '动漫', key: '7'},
                    {name: '休闲', key: '8'},
                    {name: '仙侠', key: '9'},
                    {name: '奇迹', key: '10'},
                ],
                gameList: [],
                total: 0,
                pages: 0,
                pageSize: 15,
                gameTag: '',
            }
        },
        mounted() {
            this.queryGame();
        },
        methods: {
            selectType(n) {
                this.selectGameType = n;
                if (n === 0) {
                    this.gameTag = '';
                }
                else {
                    this.gameTag = this.tagList[n].key;
                }
                this.currentPage = 1;
                this.queryGame();
            },
            changePage(e) {
                this.queryGame();
            },
            toDetail(gameId) {
                let urlPrefix = window.location.href.split('#')[0];
                let url = urlPrefix + '#/home/detail/' + gameId;
                console.log(url);
                window.open(url, '');
            },
            queryGame() {
                this.showLoading = true;
                let options = {
                    url:"/web/queryGameList",
                    data: {
                        pageNo: this.currentPage,
                        pageSize: this.pageSize,
                        params: {
                            gameTag : this.gameTag,
                        }
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let data = res.data.data;
                        this.gameList = data.list;
                        this.total = data.total;
                        console.log(this.total)
                        this.pages = data.pages;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        background-color: #eaeef3;
        min-width: 1400px;
    }
    .game_type {
        font-size: 14px;
        padding: 5px 15px;
        background-color: #eff0f5;
        border-radius: 3px;
        user-select: none;
        cursor: pointer;
        color: #666666;
        margin-left: 15px;
    }
    .game_type_selected {
        font-weight: bold;
        color: #f55050;
        background-color: rgba(245, 80, 80, 0.16);
    }
    .game-item-view {
        width: 350px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff;
        border-radius: 10px;
        margin-bottom: 20px;
        cursor: pointer;
        padding: 20px 25px;
        box-sizing: border-box;
        margin-right: 75px;
    }
    .game-center>:nth-child(3n){
        margin-right: 0;
    }
    .game-item-view:hover .game-name-one-line {
        color: #f55050;
    }
    .game-item-view:hover  {
        transform: translateY(-3px);
    }
    .game-name-one-line {
        width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        color: #333333
    }
    .game-tag-view {
        display: flex;
        color: #f3671c;
    }
    .game-tag-view span {
        margin-right: 10px;
    }
    /deep/.van-pagination__item {
        flex: unset;
        -webkit-flex: unset;
        -webkit-box-flex: unset;
    }
    /deep/.van-pagination__item--active {
    }
    .common-view {
        width: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        margin-top: 20px;
        height: 100px;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>