<template>
    <div class="dialog-main">
        <div class="pay-title">
            <span style="font-size: 13px;color: #F46C49">{{title}}</span>
            <span>需支付: {{price}}</span>
        </div>

        <div class="code-body">
            <iframe id="iframe" v-show="true" allowfullscreen ref="gameIframe"  :src="payCodeUrl" style="flex: 1;"
                    frameborder="0" scrolling="no" width="100%" height="100%"></iframe>
        </div>

        <div class="cody-footer">
            <span @click="close">已支付/关闭</span>
        </div>
    </div>
</template>

<script>

    import {request} from "@/api/request";
    import {Toast} from "vant";

    export default {
        name: "PayScanCode",
        data: function () {
            return {
                title: '',
                price: '',
                payCodeUrl: '',
                interval: 0,
            }
        },
        mounted() {
        },
        methods: {
            initPage(data) {
                if (data.payType === 21 || data.payType === 22 ) {
                    this.title = '微信扫码支付';
                }
                else {
                    this.title = '支付宝扫码支付';
                }
                this.price = data.price;
                this.payCodeUrl = data.payUrl;
                this.interval = setInterval(() => {
                    this.queryPayCodeStatus(data.orderId);
                }, 2000);
            },
            close() {
                clearInterval(this.interval);
                this.$parent.$parent.close('payScanCode');
            },
            queryPayCodeStatus (orderId) {
                let options = {
                    url: "/h5/queryPayCodeStatus?orderId=" + orderId,
                    method: 'get'
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let code = res.data.data;
                        if (code === 1) {//待支付，继续请求

                        }
                        else if (code === 0) {//已过期
                            this.close();
                            Toast('订单已过期，如要支付请重新下单');
                        }
                        else if (code === 2) {//已支付，关闭窗口
                            this.close();
                            Toast('订单支付成功');
                        }
                    }
                    else {
                        this.close();
                        Toast(res.data.error.message);
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .dialog-main {
        padding: 0 25px;
    }

    .pay-title {
        height: 40px;
        font-size: 12px;
        color: #555555;
        display: flex;
        flex-direction: column;
        padding: 10px 0 5px;
    }
    .code-body {
        width: 100%;
        height: 200px;
    }
    .cody-footer {
        font-size: 13px;
        color: #888888;
        height: 30px;
        margin-top: 15px;
        cursor: pointer;
        user-select: none;
    }
</style>