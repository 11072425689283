let from;

export default {
    openUrl(url) {
        this.initFrom();
        if (from === 'android') {
            window.androidJs.openUrl(url)
        }
        else {
            window.webkit.messageHandlers.openUrl.postMessage({url: url})
        }

    },
    startIntent(url){
        this.initFrom();
        if (from === 'android') {
            window.androidJs.startIntent(url)
        }
        else {
            window.webkit.messageHandlers.startIntent.postMessage({url: url})
        }
    },
    close(){
        this.initFrom();
        if (from === 'android') {
            window.androidJs.close()
        }
        else {
            window.webkit.messageHandlers.close.postMessage(null)
        }
    },
    logout(){
        this.initFrom();
        if (from === 'android') {
            window.androidJs.logout()
        }
        else {
            window.webkit.messageHandlers.logout.postMessage(null)
        }
    },
    copy(content){
        this.initFrom();
        if (from === 'android') {
            window.androidJs.copy(content)
        }
        else {
            window.webkit.messageHandlers.copy.postMessage({content: content})
        }
    },
    setJs(jsz){
        this.initFrom();
        if (from === 'android') {
            window.androidJs.setJs(jsz)
        }
        else {
            //window.webkit.messageHandlers.setJs.postMessage({jsz: jsz})
        }
    },
    switchAccount(accountId){
        this.initFrom();
        if (from === 'android') {
            window.androidJs.switchAccount(accountId)
        }
        else {
            window.webkit.messageHandlers.switchAccount.postMessage({content: accountId})
        }
    },
    openScanPayPage(url, payType, money){
        this.initFrom();
        if (from === 'android') {
            window.androidJs.openScanPayPage(url, payType, money)
        }
        else {
            window.webkit.messageHandlers.openScanPayPage.postMessage({url: url, payType: payType, money: money})
        }
    },
    initFrom() {
        if (!from) {
            from = localStorage.getItem('from')
        }
    }


}