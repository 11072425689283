<template>
    <div class="main-view">
        <home-nav></home-nav>
        <gzh-code-view></gzh-code-view>

        <div>
            <van-image v-if="!!topImageUrl" :src="topImageUrl" style="width: 100%;height: 270px;" fit="cover"/>
            <van-image v-else :src="require('../../assets/game/top.png')" style="width: 100%;height: 270px;" fit="cover"/>
        </div>

        <div style="width: 100%;min-height: calc(100vh - 380px); max-width: 1200px;margin: 0 auto;">
            <div style="width: 100%;height:70px; background-color: #ffffff;border-radius: 10px;margin-top: -30px;z-index: 999;position: relative;">
                <div style="height: 70px;padding-left: 50px;display: flex;align-items: center;color: #666666">
                    <span>当前位置：</span>
                    <span style="margin-left: 10px;">首页</span>
                    <span style="margin-left: 10px;">></span>
                    <span style="margin-left: 10px;">{{gameName}}</span>
                </div>
            </div>

            <div style="width: 100%;display: flex;margin-top: 20px;">
                <div style="display: flex;align-items: center; width: 100%;padding: 0 30px; height:210px;position: relative; background-color: #ffffff;border-radius: 10px;margin-right: 20px;">
                    <img :src="icon" style="width: 140px;height: 140px;border-radius: 20px;">
                    <div style="display: flex;height: 125px;color: #666666; margin-left: 25px;flex-direction: column;justify-content: space-between;align-items: flex-start">
                        <span class="game-name">{{gameName}}</span>
                        <div class="game-tag-view">
                            <span v-for="tag in tagList">{{tag}}</span>
                        </div>
                        <span>{{gameTopic}}</span>
                        <span>大小：{{gameSize}}</span>
                    </div>

                    <div style="width: 200px;height: 210px;display:flex;flex-direction: column;align-items: center;justify-content: center; position: absolute;right: 30px;">
                        <div class="download-button">
                            <img src="../../assets/android-white.png" style="width: 25px;height: 25px;margin-right: 10px;">
                            <span>安卓版下载</span>
                        </div>

                        <div class="online-button" style="margin-top: 20px;">
                            <span>在线试玩</span>
                        </div>

                    </div>

                </div>

                <div style="width: 280px;height:210px; background-color: #ffffff;border-radius: 10px;display: flex;flex-direction: column; align-items: center;justify-content: center;">
                    <div class="pay-code-panel">
                        <div class="qr-code" ref="qrCodeUrl"></div>
                    </div>
                    <span style="font-size: 13px;color: #888888;margin-top: 10px;">手机扫一扫下载游戏</span>
                </div>
            </div>


            <div style="width: 100%;background-color: #ffffff;border-radius: 10px;margin-top: 20px;padding: 20px 30px;box-sizing: border-box;margin-bottom: 20px;">
                <div class="top-vew-name">
                    游戏简介
                </div>

                <div style="line-height: 33px;text-align: left;margin-top: 16px;">
                    <span style="text-align: left;font-size: 16px;color: #444444;">{{gameDesc}}</span>
                </div>

                <div  ref="scrollable" @mousedown="startMove" @mouseup="endMove" @mouseleave="endMove" @mousemove="change"   style="width: 1140px;margin-top: 25px;overflow: hidden; white-space: nowrap;position: relative;font-size: 0;-webkit-overflow-scrolling: touch;">

                    <video v-if="!!videoUrl" :src="videoUrl" style="height: 400px;border-radius: 5px;" :controls="true" :autoplay="false" />

                    <div style="white-space: nowrap;overflow: hidden;display: inline-block">
                        <!--<img draggable="true" v-for="imageUrl in introImageUrlList" :src="imageUrl" style="height: 400px;margin-left: 15px;border-radius: 5px;"/>-->

                        <div v-for="(imageUrl,index) in introImageUrlList" style="margin-left: 15px;display: inline-block">
                            <el-image :id="'img' + index" :style="isMoving ? 'pointer-events: none' : ''"
                                    style="width: 225px; height: 400px;border-radius: 5px;"
                                    :src="imageUrl"
                                    :preview-src-list="introImageUrlList">
                            </el-image>
                        </div>


                    </div>
                </div>
            </div>

        </div>

        <home-footer></home-footer>
    </div>
</template>

<script>
    import {request} from "@/api/request";
    import homeNav from "@/components/HomeNav";
    import homeFooter from "@/components/HomeFooter";
    import gzhCodeView from "@/components/GzhCodeView";
    import {Toast} from "vant";
    import QRCode from "qrcodejs2";

    export default {
        name: "HomeDetail",
        components: {
            homeNav,
            homeFooter,
            gzhCodeView,
        },
        data() {
            return {
                gameId: '',
                gameName: '',
                gameDesc: '',
                gameTopic: '',
                gameSize: '',
                icon: '',
                tagList: [],
                mobileUrl: '',
                qrCode: {},
                videoUrl: '',
                introImageUrlList: [],
                topImageUrl: '',
                isMouseDown: false,
                isMoving: false,
                startX: 0,
                scrollLeft: 0,


            }
        },
        mounted() {
            this.mobileUrl = window.location.href;
            this.gameId = this.$route.path.split('/')[3]
            console.log(this.gameId)
            this.getGameInfo();
            this.creatQrCode();
        },
        methods: {
            startMove(e) {
                this.isMouseDown = true;
                this.startX = e.pageX - this.$refs.scrollable.offsetLeft;
                this.scrollLeft = this.$refs.scrollable.scrollLeft;
            },
            endMove(e) {
                this.isMouseDown  = false;
                this.isMoving  = false;
            },
            change(e) {
                if (!this.isMouseDown) return;
                e.preventDefault();
                let x = e.pageX - this.$refs.scrollable.offsetLeft
                let walk = (x - this.startX) * 1; // 调整滚动速度
                if (walk !== 0) {
                    this.isMoving  = true;
                    this.$refs.scrollable.scrollLeft = this.scrollLeft - walk;
                }
            },
            getGameInfo() {
                this.showLoading = true;
                let options = {
                    url:"/web/getGameInfo?id=" + this.gameId,
                    method: 'get'
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let data = res.data.data;
                        this.gameName = data.gameName;
                        this.icon = data.icon;
                        this.gameDesc = data.gameDesc;
                        this.gameTopic = data.gameTopic;
                        this.gameSize = data.gameSize;
                        this.tagList = data.tagList;
                        this.videoUrl = data.videoUrl;
                        this.topImageUrl = data.topImageUrl;
                        this.introImageUrlList = data.introImageUrlList;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            creatQrCode() {
                this.$refs.qrCodeUrl.innerHTML = ""
                if (this.qrCode._oQRCode) {
                    this.qrCode.clear(); //清除
                }
                this.qrCode = new QRCode(this.$refs.qrCodeUrl, {
                    text: this.mobileUrl,
                    width: 140,
                    height: 140,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: 3

                });
            }
        }
    }
</script>

<style scoped>
    .main-view {
        min-width: 1400px;
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        background-color: #eaeef3;
    }

    .game-name {
        text-align: left;
        font-size: 28px;
        font-weight: bold;
        color: #333333
    }
    .game-tag-view {
        display: flex;

    }
    .game-tag-view span {
        margin-right: 10px;
        background-color: rgba(238, 109, 109, 0.1);
        color: #f14848;
        font-size: 12px;
        padding: 2px 6px;
    }
    .download-button {
        width: 170px;
        height: 50px;
        border-radius: 40px;
        background-color: #ea5a0d;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        box-sizing: border-box;
        color: #ffffff;
        user-select: none;
        cursor: pointer;
    }
    .online-button {
        width: 170px;
        height: 50px;
        border-radius: 40px;
        background-color: #ffffff;
        border: 2px solid #ea5a0d;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
        box-sizing: border-box;
        color: #ea5a0d;
        user-select: none;
        cursor: pointer;
    }
    .pay-code-panel{
        text-align: center;
        overflow: hidden;
        width: 140px;
        height: 140px;
    }
    .qr-code{
        display: inline-block;
        align-items: center;
        justify-content: center;
        text-align: center;
        overflow: hidden;
    }
    .top-vew-name {
        text-align: left;
        font-family: myFont,serif;
        color: #1a66e5;
        user-select: none;
        font-size: 35px;
    }
</style>