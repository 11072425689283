<template>
    <div class="sdk-page">
        <div class="sdk-nav-bar">
            <div @click="back" style="width: 40px;height: 40px;display: flex;align-items: center;justify-content: center; position: absolute;">
                <van-icon name="arrow-left" size="20" color="#888888"/>
            </div>
            <div style="font-size: 16px;">
                小号管理
            </div>
            <van-icon @click="close" name="cross" color="#d0d0d0" size="20" class="close-x"/>
        </div>

        <div class="sdk-page-body" style="overflow-y: auto;">
            <div v-for="item in accountList" class="account-item-view" :class="item.account_id+'' === userId && 'current-view'"
                    @click="onSwitchAccount(item.account_id + '', item.account_name)">
                <div class="one-line">{{item.account_name}}</div>
                <div class="account-item-right">
                    <span v-if="item.account_id+'' === userId" style="width: 50px; font-weight: normal;font-size: 12px;color: #F46C49;margin-right: 10px;">当前登录</span>
                    <van-icon name="edit" size="16" color="#666666" @click.stop="showUpdateNameDialog(item.account_id, item.account_name)"/>
                </div>
            </div>
        </div>

        <div class="main-bottom-button">
            <div @click="showAddDialog" class="main-button">添加小号</div>
        </div>
        <myloading v-show="showLoading"></myloading>

        <van-dialog v-model="showInputName" :title="dialogTitle" @confirm="submit" show-cancel-button style="color: #333333">
            <div style="padding: 0 15px;margin-bottom: 10px;margin-top: 10px;">
                <van-field  maxlength="10"  placeholder="请输入小号名称" class="input-view" clearable v-model="inputName" />
            </div>
        </van-dialog>

    </div>
</template>

<script>
    import {sdkRequest} from "@/api/request";
    import myloading from '@/components/myloading'
    import {Toast, Dialog} from "vant";
    import '../../css/sdkcommon.css'
    import sdkNotice from "@/api/sdk-notice";

    export default {
        name: "SdkAccount",
        components: {
            myloading
        },
        data: function () {
            return {
                showLoading: false,
                accountList: [],
                userId: 0,
                selectedId: '',
                inputName: '',
                showInputName: false,
                isAdd: false,
                dialogTitle: '',
            }
        },
        mounted() {
            this.userId = localStorage.getItem('userId');
            this.queryAccountList();
        },
        methods: {
            back() {
                this.$router.back();
            },
            close() {
                sdkNotice.close();
            },
            queryAccountList() {
                this.showLoading = true;
                let options = {
                    url:"/sdk/h5/getAccountList",
                    data: {}
                };
                sdkRequest(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.accountList = res.data.data.accountList;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            onSwitchAccount(accountId, name) {
                if (accountId === this.userId) {
                    return;
                }
                Dialog.confirm({
                    message: '确认要切换到 ' + name + ' 吗？',
                }).then(() => {
                    sdkNotice.switchAccount(accountId);
                }).catch(() => {
                });
            },
            showAddDialog() {
                this.dialogTitle = '添加小号';
                this.inputName = '';
                this.isAdd = true;
                this.showInputName = true;
            },
            showUpdateNameDialog(id, name) {
                this.dialogTitle = '修改小号名称';
                this.selectedId = id;
                this.inputName = name;
                this.isAdd = false;
                this.showInputName = true;
            },
            submit() {
                if (this.isAdd) {
                    this.submitAdd();
                }
                else {
                    this.submitUpdate();
                }
            },
            submitAdd() {
                if (!this.inputName) {
                    Toast('请输入小号名称');
                    return;
                }
                this.showLoading = true;
                let options = {
                    url:"/sdk/h5/addAccount",
                    data: {
                        accountName: this.inputName.trim(),
                    }
                };
                sdkRequest(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('小号创建成功');
                        this.queryAccountList();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            submitUpdate() {
                if (!this.inputName) {
                    Toast('请输入小号名称');
                    return;
                }
                this.showLoading = true;
                let options = {
                    url:"/sdk/h5/updateAccountName",
                    data: {
                        accountName: this.inputName.trim(),
                        accountId: this.selectedId
                    }
                };
                sdkRequest(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('小号名称修改成功');
                        this.queryAccountList();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .account-item-view {
        background-color: #f7f7f7;
        height: 35px;
        margin-top: 5px;
        border-radius: 3px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        color: #333333;
    }
    .current-view  {
        font-weight: bold;
        background-color: rgba(244, 108, 73, 0.1);
    }
    .account-item-right {
        display: flex;
        align-items: center;
    }
    .main-button {
        background: #F46C49;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        font-size: 12px;
        width: 75px;
        font-weight: bold;
        border-radius: 3px;
        cursor: pointer;
        user-select: none;
    }

    .main-bottom-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
    }
    .one-line {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>